import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <div className='w-full flex items-center justify-between bg-black/20 min-h-[80px]'>
        <img src="https://www.freepnglogos.com/uploads/netflix-logo-0.png" alt="Netflix Logo" className='w-24 ml-10'/>
        <div className='flex items-center justify-center gap-6 px-12'>
            <Link to='/' className='text-white text-base hover:scale-105 hover:underline transition-all '>Anasayfa</Link>
            <Link to='/' className='text-white text-base hover:scale-105 hover:underline transition-all '>Hakkımızda</Link>
            <Link to='/' className='text-white text-base hover:scale-105 hover:underline transition-all '>Ekibimiz</Link>
            <Link to='/' className='text-white text-base hover:scale-105 hover:underline transition-all '>Dergiler</Link>
            <Link to='/' className='text-white text-base hover:scale-105 hover:underline transition-all '>Etkinlik kayıt</Link>
        </div>
    </div>
  )
}

export default Navbar