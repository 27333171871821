import React, { useRef } from 'react'
import Navbar from '../Components/Navbar'
import homeBackground from '../Assets/homeBackground.svg'
import { Canvas, useFrame } from '@react-three/fiber'
import { Suspense } from 'react'
import { OrbitControls } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";



const Home = () => {

    const model = useRef()

    const [x,setX] = React.useState('')
    const [y,setY] = React.useState('')
   

    document.addEventListener('mousemove', (e) => {
        const x = e.pageX / window.innerWidth
        const y = e.pageY / window.innerHeight
        setX(x)
        setY(y)
    })


    const Model = () => {

         useFrame(() => {
            model.current.rotation.y = x * .7
            
    })
        const fbx = useLoader(FBXLoader, "glados.fbx");
        return <primitive object={fbx} scale={0.0010} />;



    }


    


    return (
        <div
            
            className='w-full h-screen bg-center bg-cover bg-no-repeat bg-[#393e46]   flex-col flex items-start justify-start'>
            <Navbar></Navbar>
            <Canvas>
                <ambientLight intensity={5} shadow={true} />
                <Suspense fallback={null}>
                    <OrbitControls enableRotate={false} enableZoom={false} enablePan={false}  />
                  <mesh ref={model} position={[6,-2,0]}>
                  <Model></Model>
                  </mesh>
                </Suspense>
            </Canvas>
            


        </div>
    )
}

export default Home